@import "src/styles/base/variables";

.mobLogin {
  background-color: map_get($colors, green);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__content {
    padding: 0 70px;
    text-align: center;
  }

  &__input {
    margin-top: 60px;
    margin-bottom: 40px;
    color:map_get($colors, white);
    font-size: 40px;
    input {
      color:map_get($colors, black);
      max-width: 275px;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      width: 100% !important;
    }

  }

  &__button {
    button {
      max-width: 275px;
    }
  }

  &__qr {
    font-size: 18px;
    color: map_get($colors, white);
    text-decoration: underline;
    font-weight: 700;
    margin: 30px 0 0;
  }
}

.point-cup-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .confetti-emitter {
    align-self: center;
  }

  .point-cup-image {
    width: 200px;
    height: 200px;
  }

  .score {
    font-family: $GoetheFFClan;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.11px;
    color: #000;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
}

.progress-container {
  display: flex;
  width: 100%;
  background-color: white;
  margin-top: 60px;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;

  .progress-inner-container {
    flex: 1;
    padding-right: 10px;
  }

  .progress-text {
    font-size: 16px;
  }
}
