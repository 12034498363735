@import 'src/styles/base/variables';

.c-mobileHead {
  padding: 10px 20px;
  background-color: map_get($colors, white);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &_green {
    background-color: map_get($colors, green);
  }

  .header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .right-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      flex: 1;
    }

    .hamburger-menu{
      font-size: 22px;
    }

    .logo {
      height: 40px;
      width: auto;
    }

    .player {
      margin-right: 20px;
      font-size: 18px;
      padding-top: 5px;
    }
  }
}
