@import 'src/styles/base/variables';
.c-privateContent {
  background: map_get($colors, white);
  min-height: calc(100vh - 55px) !important;
  section.ant-layout{
    flex-direction: row;
  }
}
.header-saving {
  background-color: white;
  height: 55px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 15px;
  padding-left: 21px;
  padding-right: 21px;
  color: map_get($colors, green);
  font-weight: bold;
  .anticon-spin {
    vertical-align: middle;
    font-size: 22px;
  }
}
