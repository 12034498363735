@import 'src/styles/base/variables';
.c-admin {
  &-sider {
    &-menu {
      min-height: calc(100vh - 55px) !important;
      width: 200px;
      .c-menu-item{
        &__active{
          font-weight: bold;
        }
      }
    }
  }
}
.c-admin-content {
  background: map_get($colors, white);
  min-height: calc(100vh - 55px) !important;
}
