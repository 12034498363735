@import 'src/styles/base/variables';
.c-modal {

  .ant-modal-content {
    border: solid 2px map-get($colors, black);

    .ant-modal-close {
      color: map-get($colors, black);

      .ant-modal-close-x {
        font-size: 30px;
      }
    }

    .ant-modal-body {
      margin: 75px 40px 64px 40px;
      height: 75vh;
      overflow-y: scroll;
    }

  }
}
