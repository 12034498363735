@import 'src/styles/base/variables';
.c-login {
  max-width: 100%;
  width: 100%;
  height: 100rem;  
  &__form {
    background: map_get($colors, green);
    margin-top: 2rem !important;
    padding: 2rem !important;

    div {
      text-align: center;
    }
  }
}
