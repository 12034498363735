@import 'src/styles/base/variables';
.c-createQuiz {
  $self: &;
  width: 100%;

  &-modal {

    &-icon {
      position: absolute;
      top: 10px;
      right: 50px;
      font-size: 30px;
    }

    &-card {
      height: 140px;
      border-radius: 10px;
      background-color: map-get($colors, green);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      font-weight: bold;
      color: map-get($colors, white);
      cursor: pointer;
    }
  }

  &-content {
    padding: 55px;
    background-color: map-get($colors, white);
    overflow-y: scroll;
    height: calc(100vh - 55px);

    &-item {
      border-radius: 10px;
      border: solid 2px map-get($colors, black);
      display: flex !important;
      justify-content: center;
      align-items: center;
      // margin-top: 50px;

      &__title {
        border: none;
        justify-content: flex-start;
        font-size: 36px;
        font-weight: bold;
      }

      &__subtitle {
        justify-content: flex-start;
        font-size: 18px;
        font-weight: bold;
        border: none;
        margin-top: 30px;
        .error-label{
          margin-left: 20px;
          font-size: 18px;
          width: 50%;
          height:30px
        }
        &.has-error{
          color: map-get($colors, orange);
        }
      }

      &__secondSubtitle {
        justify-content: flex-start;
        font-size: 14px;
        border: none;
        margin-top: 10px;
      }

      &__total {
        flex-direction: column;
        margin-top: 0;
        padding:10px;
        div {
          font-size: 18px;
          font-weight: bold;
        }
      }

      &__image {
        height: 356px;
        margin-top: -20px;
        border: 0px;
      }

      &__tag {
        margin: 20px 30px 0 0;
        cursor: pointer;

        &__active {
          background-color: map-get($colors, green);
          border: solid 2px map-get($colors, green);
        }
        &.has-error{
          border-color: map-get($colors, orange);
        }
        div {
          font-size: 18px;
          color: map-get($colors, black);
          margin: 14px 20px;
          font-weight: bold;
        }
      }
    }

    &-buttons {
      flex-direction: row;
      display: flex;
      margin-top: 30px;

      button {
        margin-right: 30px;
      }
    }

    &-input {
      font-size: 20px !important;
      font-weight: bold;
      width: 100% !important;
      border-radius: 10px !important;
      border: solid 2px map-get($colors, black) !important;

      &:hover {
        border-color: map-get($colors, black);
      }

      &:focus {
        border-color: map-get($colors, black);
      }
      &.has-error{
        border-color: map-get($colors, orange) !important;
      }
  
    }

    &-deleteButton {
      padding-top: 40px;
      display: flex;
      justify-content: center;

      button {
        width: auto;
        background: map-get($colors, red) !important;
        border: solid 4px map-get($colors, red) !important;
      }
    }
  }
}

.ant-modal-body {
  height: auto !important;
}
