@import 'src/styles/base/variables';
.c-start {
  width: 100%;

  &-modal {

    &-question {
      border-radius: 10px;
      border: solid 2px map-get($colors, black);
      margin-bottom: 20px;
      padding: 20px;

      p {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }

  &-slider {
    margin-top: 75px;

    &-column {
      display: flex !important;
      justify-content: center;
      align-items: center;

      &-icon {
        width: 41px;
        height: 75px;
        cursor: pointer;

        &__right {
          transform: scaleX(-1);
        }
      }

      &__nested {
        height: 187px;
        background: map-get($colors, green);
        cursor: pointer;
        text-align: center;
        overflow: hidden;
      }

      &__active {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
  }

  &-search {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 61px;

    .ant-input-affix-wrapper {
      height: 66px;
      border: solid 3px map-get($colors, black);
      border-radius: 10px;
      padding-right: 20px;

      &:hover {
        border-color: map-get($colors, black);
      }

      .ant-input {
        font-size: 20px;
        font-weight: bold;
      }

      .ant-input-suffix {
        font-size: 36px;
      }
    }
  }

  &-cards {
    margin: 75px 50px 0 50px;

    &-header {
      font-size: 36px;
      font-weight: bold;
      letter-spacing: 1px;
      border-bottom: solid 2px map-get($colors, black);

      &__right {
        display: flex !important;
        align-items: center;

        span {
          font-size: 18px;
          margin-right: 10px;
          white-space: nowrap;
        }
      }
    }

    &-list {
      margin-top: 44px;
    }

    &-error {
      text-align: center;
    }

    &-more {
      margin-top: 35px;
      margin-bottom: 49px;

      &-button {
        width: 100%;
        height: 71px !important;
        border-radius: 10px !important;
        border: solid 4px map-get($colors, green) !important;
        background: map-get($colors, green) !important;
        color: map-get($colors, white) !important;
        font-size: 25px !important;
        font-weight: bold !important;
      }
    }

    .total-count {
      font-size: 20px;
      margin-top: 5px;
    }
  }
  .sort-direction-btn {
    margin-right: 10px;
    margin-left: 10px;
    border: solid 1px #000;
    padding: 10px;
    border-radius: 6px;
  }
}
