@import 'src/styles/base/variables';

.copyright {
  position: absolute;
  right: 2rem;
  bottom: 10px;
  color: #fff;
  font-family: "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.1rem;
  line-height: 1.15;
  text-shadow: 0.0825rem 0.0825rem 0.0825rem rgb(0 0 0 / 59%);

  .edit-container {
    background-color: #FFFFFF99;
    border-radius: 100px;
    padding: 5px 6px;
    cursor: pointer;
  }
}