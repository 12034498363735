@import 'src/styles/base/variables';
.ant-btn,
.ant-btn:focus,
.ant-btn:hover,
.ant-btn:active {
  background-color: map_get($colors, white);
  border-color: map_get($colors, green) !important;
  color: map_get($colors, black) !important;
  &.ant-btn-primary {
    background-color: map_get($colors, green);
    border-color: map_get($colors, green);
    color: map_get($colors, white) !important;
    &:active {
      background-color: map_get($colors, green);
      border-color: map_get($colors, green);
    }
    &:hover {
      background-color: map_get($colors, green);
      border-color: map_get($colors, green);
    }
    &:focus {
      background-color: map_get($colors, green);
      border-color: map_get($colors, green);
    }
  }
}
.c-button {
  padding: 20px;
  border-radius: 10px;
  color: map_get($colors, black);
  font-size: 20px;
  letter-spacing: 0.31px;
  box-shadow: none;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &_primary {
    background-color: map_get($colors, white);
  }

  &_secondary {
    background-color: map_get($colors, white);
  }

  &_link {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
  }

  &_block {
    width: 100%;
  }
}
