@import "src/styles/base/variables";

.mq-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 20px;

  &_success {
    background-color: map-get($colors, green);
  }

  &_failure {
    background-color: map-get($colors, orange);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title,
  &__subTitle,
  &__text {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: map-get($colors, white);

    &.thanks-for-answer {
      margin-top: 0;
      margin-bottom: 50px;
      text-align: center;
    }
  }

  &__title {
    font-size: 50px;
    letter-spacing: 0.69px;
    margin: 45px 0 0;
    text-align: center;
  }

  &__subTitle {
    font-size: 40px;
    letter-spacing: 0.56px;
    margin: 45px 0 0;
  }

  &__text {
    font-size: 18px;
    letter-spacing: 0.5px;
    text-align: center;
    max-width: 250px;
    margin: 40px 0 0;
  }

  &__footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: map-get($colors, white);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__footerItem {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: map-get($colors, black);
  }
}
