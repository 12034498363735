@import 'src/styles/base/variables';
.image-dropzone {
  width: 100%;
  height: 100%;
  .dropzone {
    width: 100%;
    height: 100%;
    cursor: pointer;
    p {
      text-align: center;
      &.error {
        font-size: 14px;
        color: red;
      }
    }
  }
  .image {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content:  space-between;
    .ant-spin {
      position: absolute;
      top: 45%;
      left: 45%;
      color: map-get($colors, green);
    }
    &__container {
      position: relative;
      display: flex;
      flex-grow:0;
      flex-shrink:0;
      margin: 0px auto;
      padding: 0;
      // border:1px solid red;
      // align-items: center;
      // flex-direction:column;
      &:hover{
        .c-quiz-item-icon {
          visibility:visible;
        }
      }

      .c-quiz-item-icon {
        top: 45px;
        left: 20px;
        position: absolute;
        visibility:visible;
        transform: translate(-50%, -130%);
        background-color: rgba(255, 255, 255, 0.6);
        width: 32px;
        border-radius: 1em;
        padding: 5px;
        font-size: 22px;
        cursor: pointer;
      }
      img {
        &.tmp-image {
          opacity: 0.3;
        }
        object-fit: contain;
        padding: 0;
        width: auto;
      }
    }
  }
}
