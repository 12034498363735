@import "src/styles/base/variables";
.welcomeQuiz {
  width: 100%;
  min-height: calc(100vh - 55px);
  background-color: map_get($colors, green);
  padding: 55px 0;

  &__inner {
    max-width: 1140px;
    margin: 0 auto;
  }

  &__banner {
    background-color: map_get($colors, white);
    padding: 35px 50px;
    border-radius: 5px;
    font-size: 40px;
    letter-spacing: 0.56px;
    font-weight: bold;
    color: map_get($colors, black);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
    gap: 20px;
  }

  &__bannerQR {
    width: 260px;
    min-width: 260px;
    height: 260px;
    background-color: gray;
  }

  &__players {
    margin-top: 40px;
    font-size: 60px;
    letter-spacing: 0.83px;
    font-weight: bold;
    color: map_get($colors, white);
  }

  &__playersList {
    margin: 30px 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    gap: 40px;
  }

  &__playersListItem {
    font-size: 40px;
    letter-spacing: 0.56px;
    font-weight: 700;
    color: map_get($colors, black);
    padding: 16px 40px;
    background-color: map_get($colors, white);
    border-radius: 5px;
    cursor: pointer;
  }
}
