@import 'src/styles/base/variables';
.c-myQuizzes {
  width: 100%;

  &-modal {

    &-question {
      border-radius: 10px;
      border: solid 2px map-get($colors, black);
      margin-bottom: 20px;
      padding: 20px;

      p {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0;
      }
    }

    &-folder {
      display: flex !important;
      justify-content: center;

      &-title {
        font-size: 36px;
        font-weight: bold;
      }

      &-input {
        margin-top: 20px;

        input {
          width: 507px !important;
          border: solid 3px map-get($colors, black);
          border-radius: 10px;
          font-size: 20px;
          font-weight: bold;
        }
      }

      &-button {
        margin-top: 20px;
        width: 35%;
      }
    }
  }

  &-search {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 75px 0;

    .ant-input-affix-wrapper {
      height: 66px;
      width: 507px !important;
      border: solid 3px map-get($colors, black);
      border-radius: 10px;
      padding-right: 20px;
      padding-left: 30px;

      &:hover {
        border-color: map-get($colors, black);
      }

      .ant-input {
        font-size: 20px;
        font-weight: bold;
      }

      .ant-input-suffix {
        font-size: 36px;
      }
    }
  }

  &-folders {
    margin: 0 50px;

    &-header {
      font-size: 36px;
      font-weight: bold;
      letter-spacing: 1px;
      border-bottom: solid 2px map-get($colors, black);
    }

    &-list {
      margin-top: 55px;

      &-item {
        cursor: pointer;
        display: flex !important;
        justify-content: center;
        align-items: center;
        height: 187px;
        background-color: map-get($colors, green);

        &-title {
          font-size: 25px;
          font-weight: bold;
          color: map-get($colors, white);
        }

        &__add {
          flex-direction: column;
          opacity: 0.5;

          &-icon {
            margin-top: 16px;
            font-size: 45px;
            line-height: 45px;
            font-weight: bold;
            color: map-get($colors, white);
          }

          &-title {
            margin-top: 16px;
            font-size: 16px;
            line-height: 16px;
            font-weight: bold;
            letter-spacing: 0.25px;
            color: map-get($colors, white);
          }
        }

        &__active {
          position: absolute;
          top: 5px;
          right: 32px;
        }
      }
    }
  }

  &-cards {
    margin: 70px 50px 0 50px;

    &-header {
      font-size: 36px;
      font-weight: bold;
      letter-spacing: 1px;
      border-bottom: solid 2px map-get($colors, black);

      &__right {
        display: flex !important;
        align-items: center;

        span {
          font-size: 18px;
          font-weight: normal;
          margin-right: 10px;
          white-space: nowrap;
        }
      }
    }

    &-list {
      margin-top: 55px;
    }

    &-more {
      margin-top: 35px;
      margin-bottom: 49px;

      &-button {
        width: 100%;
        height: 71px !important;
        border-radius: 10px !important;
        border: solid 4px map-get($colors, green) !important;
        background: map-get($colors, green) !important;
        color: map-get($colors, white) !important;
        font-size: 25px !important;
        font-weight: bold !important;
      }
    }

    .total-count {
      font-size: 20px;
      margin-top: 5px;
    }
  }

  .sort-direction-btn {
    margin-right: 10px;
    margin-left: 10px;
    border: solid 1px #000;
    padding: 10px;
    border-radius: 6px;
  }

}
