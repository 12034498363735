@import "src/styles/base/variables";

.quizMobile {
  $self: &;
  min-height: 100vh;
  padding: 30px 20px 0;

  &_manyAnswers {

    #{ $self }__answersItem {
      padding: 30px 0 0;

      span {
        font-size: 180px;
      }
    }

    #{ $self }__settings {
      margin: 75px 0 0;
    }
  }

  &_pureAnswers {

    #{ $self }__answers {
      gap: 30px
    }

    #{ $self }__answersItem {
      flex-basis: 100%;
      max-width: 100%;
    }

    #{ $self }__settings {
      margin: 30px 0 0;
    }
  }

  &_form {
    background-color: map-get($colors, green);
    position: relative;
  }

  &__title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.42px;
    color: map-get($colors, black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__countDown {
    position: relative;
    text-align: center;
    margin: 10px 0 0;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 60px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.67px;
      color: map-get($colors, black);
    }
  }

  &__answers {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    margin: 15px 0 0;
  }

  &__answersItem {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
    border-radius: 15px;
    padding: 50px 0 0;
    overflow: hidden;
    text-align: center;

    span {
      font-size: 20vh;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 7.78px;
      text-align: center;
      color: map-get($colors, white);

    }

    &_selected {
      position: relative;

      &:after {
        display: block;
        content: "";
        position: absolute;
        top: 7.5px;
        right: 7.5px;
        bottom: 7.5px;
        left: 7.5px;
        border: 8px solid map-get($colors, white);
        border-radius: 15px;
      }
    }

  }

  &__form {
    padding: 0 50px;
    margin: 70px auto 0;
  }
  &__text {
    display: flex;
    flex-grow: 1;
    padding: 0 50px;
    margin: 70px auto 0;
    justify-content: center;
  }

  &__openAnswer {
    position: relative;
    font-size: 18px;
    color: #8d8d8d;

    input {
      width: 100%;
      height: 125px;
      padding: 50px;
      opacity: 0.7;
      outline: navajowhite;
      resize: none;
      border-radius: 15px;
      box-shadow: none;
      border: 0;
      color: black;
    }
  }

  &__formButton {
    margin: 40px 0 0;
  }

  &__settings {
    padding: 15px 0;
    margin: 40px 0 0;
    border-top: 1px solid map-get($colors, black);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_white {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      background-color: map-get($colors, white);
      border: none;
    }
  }

  &__settingsItem {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: map-get($colors, black);
    text-transform: uppercase;
  }
}
