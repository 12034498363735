@import 'src/styles/base/variables';

.overlay-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $modal-bg;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-spin-dot-item {
        background-color: map_get($colors, green);;
    }
  }