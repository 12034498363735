@import 'src/styles/base/variables';
.c-publicContent {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: map_get($colors, green);
  min-height: calc(100vh - 55px) !important;
}
