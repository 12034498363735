@import 'src/styles/base/variables';
.c-createQuiz-sider {
    $self: &;

    flex: 0 0 20% !important;
    max-width: 20% !important;
    min-width: 250px !important;
    width: 20% !important;
    border-right: solid 2px map-get($colors, black);

    &-slides {
      height: calc(100vh - 255px);
      padding: 30px 20px 0 20px;
      overflow-y: scroll;

      &-item {
        background-color: map-get($colors, green);
        opacity: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        min-height: 100px;
        max-height: 100px;
        height: auto;
        cursor: pointer;
        position: relative;

        &:hover {
          // #{ $self }-sider-slides-item-icon {
          .c-createQuiz-sider-slides-item-icon {
            visibility: visible;
          }
        }
        
        &.has-error{
          background-color: map-get($colors, orange);

        }

        &-title {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding: 10px 20px;
          font-size: 18px;
          font-weight: bold;
          color: map-get($colors, white);
        }

        &-icon {
          visibility: hidden;
          position: absolute;          
          top: 5px;
          right: 5px;
          width: 21px;
          height: 21px;        
          cursor: pointer;
        }

        &__active {
          opacity: 1;
        }
      }
    }

    &-buttons {
      height: 200px;
      padding: 30px 20px;

      &-item {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: solid 4px map-get($colors, green) !important;
        background: map-get($colors, green) !important;
        color: map-get($colors, white) !important;
        font-size: 18px !important;
        font-weight: bold !important;
        &.has-error{
          background-color: map-get($colors, orange) !important;
          border-color: map-get($colors, orange) !important;
        }
      }
    }
  }
