@import '~normalize.css';

@import './base/variables';
@import './base/layouts';

@font-face {
  font-family: 'GoetheFFClan';
  src: url('./fonts/GoetheFFClan/ClanCompPro-NarrowMedium.ttf') format("truetype");
}
@font-face {
  font-family: 'HelveticaNeueBd';
  src: url('./fonts/HelveticaNeue/HelveticaNeueBd.ttf') format("truetype");
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('./fonts/HelveticaNeue/HelveticaNeue.ttf') format("truetype");
}
@font-face {
  font-family: 'HelveticaNeueLt';
  src: url('./fonts/HelveticaNeue/HelveticaNeueLt.ttf') format("truetype");
}
$GoetheFFClan: 'GoetheFFClan', sans-serif;
$HelveticaNeueBd: 'HelveticaNeueBd', sans-serif;
$HelveticaNeue: 'HelveticaNeue', sans-serif;
$HelveticaNeueLt: 'HelveticaNeueLt', sans-serif;

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 30px;
  font-family: GoetheFFClan, sans-serif;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;

  
}

li.public-DraftStyleDefault-unorderedListItem {
  margin-left: 1em !important;
}

img {
  display: block;
  width: 100%;
  height: auto;
}
