@import 'src/styles/base/variables';
.c-select {
  border-radius: 6px;
  width: 100%;

  .ant-select-selector {
    height: 43px !important;
    border-radius: 6px !important;
    border: solid 1px map-get($colors, black) !important;

    .ant-select-selection-item {
      line-height: 42px !important;
    }
  }

  .ant-select-arrow {
    color: map-get($colors, black);
  }
}
