@import 'src/styles/base/variables';

.c-quiz-item.c-quiz-item__answer:hover {
  .c-quiz-item-icon__close {
    visibility: visible;
    
  }
}
.error-label{
  color: map-get($colors, orange);
  font-size: 22px;
  min-height: 30px;
  margin-top: -30px;
}
.c-quiz {
  &-item {
    border-radius: 10px;
    border: solid 2px map-get($colors, black);
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    &.has-error{
      border-color: map-get($colors, orange) !important;
    }

    &-title {
      font-size: 22px;
      font-weight: bold;
      color: map-get($colors, gray);
    }

    &-input {
      position: relative;
      border-radius: 10px !important;
      box-shadow: 0 0 0 2px map-get($colors, white) !important;
      font-size: 20px !important;
      font-weight: bold;
      width: 100% !important;
      border: solid 2px map-get($colors, black) !important;

      &:hover {
        border-color: map-get($colors, black);
      }

      &:focus {
        border-color: map-get($colors, black);
      }

      &:focus {
        border: none;
      }
      &.has-error{
        border-color: map-get($colors, orange) !important;
      }

      &.true-false {
        margin: 0;
      }
    }

    &-icon {
      position: absolute;
      top: 15px;
      right: 15px;

      &__selected {
        color: map-get($colors, green) !important;
      }

      &__unselected {
        background-color: map-get($colors, gray);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        &.has-error{
          background-color: map-get($colors, orange);
        }
      }

      &__close {
        visibility: hidden;
        left: 15px;
        width: 21px;
        height: 21px;
        cursor: pointer;
      }

      &.true-false {
        top: 8px;
      }

      &.multiple {
        top: 8px;
      }
    }

    &__image {
      height: 356px;
      font-size: 22px;
      font-weight: bold;
      color: #8d8d8d;
      border: 0px;

    }

    &__points {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 50px;
      border: none;
    }

    &__answer {
      height: 7vh;
      // cursor: pointer;

      input {
        border: none !important;
        margin: 0 48px;
      }
    }

    &__textarea {
      border: none;

      textarea {
        border: solid 2px map-get($colors, black);
      }
    }
  }

  .editor-container {
    flex: 1;
    margin-top: 20px;

    .editor-btn {
      margin-right: 20px;
      margin-bottom: 20px;
    }

    .textarea-container {
      border: solid 2px map-get($colors, black);
      border-radius: 8px;
      margin-top: 20px;
      padding: 4px 11px;
      font-size: 20px;
      min-height: 200px;

      .placeholder-hidden .public-DraftEditorPlaceholder-root {
        display: none;
      }
    }
  }
}
