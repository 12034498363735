@import 'src/styles/base/variables';
.c-input {
  border-radius: 10px !important;
  width: 323px !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  height: 66px;

  &__textarea {
    border-radius: 10px !important;
    height: 150px !important;
  }
}
