$colors: (
  white: #fff,
  black: #000,
  blue: #1890ff,
  purple: #050038,
  green: #a0c814,
  orange: #eb6400,
  green_second: rgba(255, 255, 255, 0.7),
  gray: #8d8d8d,
  red: #ff0000
);

$charcoal-grey: #474d50;
$header-background-color: #F2F2F2;
$modal-bg: rgba(0, 0, 0, 0.45);

$breakpoints: (
  mobile: 768px,
  tablet: 992px,
  desktop: 1200px
);

$shadows: (
  default: 0 .2rem .4rem rgba(0, 0, 0, 0.2)
);

$GoetheFFClan: 'GoetheFFClan', sans-serif;
$HelveticaNeueBd: 'HelveticaNeueBd', sans-serif;
$HelveticaNeue: 'HelveticaNeue', sans-serif;
$HelveticaNeueLt: 'HelveticaNeueLt', sans-serif;

$breakppoints: (
  xs:480,
  sm:576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);