@import 'src/styles/base/variables';
.c-header {
  height: 55px !important;
  background: map_get($colors, green) !important;

  .icon {
    width: 25px;
    height: 25px;
    object-fit: contain;
    margin-right: 10px;
  }

  .items-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: -25px;
    height: 55px;

    &-item {
      font-weight: normal;
      font-size: 18px;
      color: map-get($colors, white);
      margin: 15px 25px;
      letter-spacing: 0.5px;
      line-height: 23px;
      cursor: pointer;

      &__active {
        font-weight: bold;
      }

      &__black {
        color: map-get($colors, black);
      }

      &__right {
        margin: 0 15px;
      }

      img{
        height: 32px;
        width: auto;
      }
    }

    .auto-cursor {
      cursor: auto;
    }

    .prefix-right-items-container {
      height: 55px;
      position: absolute;
      top: 0;
      right: 240px;

      .preview-arrow {
        font-size: 25px;
      }

      .disabled-arrow {
        cursor: auto;
        color: map-get($colors, gray);
      }
    }
  }

  &__white {
    background: map_get($colors, white) !important;
    color: map-get($colors, white);
  }

  .hamburger-menu{
    position: absolute;
    right: 10px;
    margin: 0 15px;
    font-size: 22px;

    &.is-white {
      color: white;
    }
  }
  .no-vertical-margin {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.navbar.header{
  height: 45px;
  background-color: $header-background-color;
  padding: 0 20px;
  .icon {
    width: 25px;
    height: 25px;
    object-fit: contain;
    margin-right: 10px;
  }

  .title {
    font-family: $GoetheFFClan;
    font-size: 13px;
    font-weight: bold;
    margin-top: 13px;
    letter-spacing: -0.11px;
    color: $charcoal-grey;
    text-transform: uppercase;
    cursor: pointer;
    &.active{
      text-decoration: underline;
    }

  }

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.header-menu{
  padding: 16px !important;
  padding-left: 6px !important;
  padding-bottom: 5px !important;
  width: 280px;
  h1{
    font-size: 22px;
    font-weight: bold;
    letter-spacing: -0.34px;
    justify-content: center;
    color: #474d50;
    margin: 0 10px 10px;
    font-family: $GoetheFFClan;
  }
  .item{
    font-family: $HelveticaNeueBd;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
    padding-left: 0 !important;

    img{
      display: inline;
      width: auto;
    }
  }
  .sub-item{
    padding-left: 38px !important;
    font-family: $HelveticaNeue;
    font-size: 12px;
    font-stretch: normal;
    .active{
      font-family: $HelveticaNeueBd;
    }
  }
  .icon{
    font-size: 18px !important;
    margin-right: 10px;
    margin-left: 10px;
  }
}
