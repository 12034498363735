@import "src/styles/base/variables";

.quiz {
  $self: &;
  padding: 0 50px 70px;

  &.quiz_result {
    padding-bottom: 0px;
    min-height: calc(100vh - 55px);
  }

  &_openQuestion {
    #{ $self }__content {
      gap: 32px;
    }

    .figure-container {
      position: relative;

      .answers-count {
        position: absolute;
        inset: 0;
        color: white;
        text-align: center;
        justify-content: center;
        align-items: flex-end;
        display: flex;
        font-size: 25px;
        margin-bottom: 12px;
      }
    }
  }

  &_two-answers {

    #{ $self }__answerContent {
      top: 0;
      bottom: 0;
      font-size: 7.7vh;
      line-height: 1;
      letter-spacing: 1.67px;
    }

    #{ $self }__answer {
      padding: 7.85vh 0 5.24vh;
    }
  }

  &_without-answers {

    #{ $self }__content {
      flex-direction: column;
      flex-wrap: nowrap;
      .quiz__description{
        font-size: 3vh;

        ol {
          li {
            list-style: decimal;
          }
        }

        ul {
          padding-left: 10px;

          li {
            list-style: circle;
            list-style-type: disc;
            margin-left: 1em !important;
          }
        }
      }
    }

    #{ $self }__image {
      flex: 1;
      max-width: 100%;
      max-height:40vh;

      img {
        width: auto;
        margin: 0 auto;
        object-fit: contain;
        max-height: 40vh;
      }
    }
  }

  &__content {
    padding-top: 5.24vh;
    display: flex;
    flex-wrap: wrap;
    row-gap: 5vh;

    &_withoutImage {
      padding-top: 13.1vh;
    }

    &.result {
      flex-direction: column;
      flex-wrap: nowrap;
      grid-row-gap: 0;
      row-gap: 0;
    }

    &.final_result {
      min-height: calc(100vh - 55px);
    }
  }

  &__title {
    width: 100%;
    font-size: 7vh;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.97px;
    color: map-get($colors, black);

    &_halfWidth {
      flex: 0 0 50%;
      max-width: 50%;
      height:40vh;
    }
  }

  &__subTitle {
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.83px;
    color: map-get($colors, black);
    width: 100%;
  }

  &__description {
    padding: 0 100px;
    margin: 0 auto;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0.83px;
  }

  &__image,
  &__settings {
    flex: 0 0 50%;
    max-width: 50%;
  }
  &__image{
    display: flex;
    height: 30vh;
    position: relative;
    flex-direction: row;
    justify-content: center;

    .image-container {
      width: auto !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    
    img{
      object-fit: contain;
      width: auto !important;
      height: 100% !important;
    }
  }

  &__openQuestionList {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 40px 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: center;

  }

  &__openQuestionItem {
    display: flex;
    font-size: 7vh;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.42px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px;
    color: map-get($colors, white);
  }

  &__settingsList {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }

  &__settingsItem {
    width: auto;
    // min-width: 210px;
    height: 25vh;
    position: relative;
    svg{
      height: 25vh;
      width: 25vh;
    }

    span {
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 7vh;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.67px;
      text-align: center;
      color: map-get($colors, white);
    }

    &:last-of-type {
      span {
        color: map-get($colors, black);
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__settingsItemTimerBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #d5e892;
  }

  &__result {
    margin: 2.62vh 0 0;
  }

  &__resultTotal {
    font-size: 3.2vh;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.69px;
    color: map-get($colors, black);
  }

  &__resultList {
    display: flex;
    justify-content: center;
    flex: 1;
  }

  &__resultItem {
    padding: 0 2vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__resultItemStats {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    span {
      font-size: 3.8vh;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.83px;
      text-align: center;
      color: map-get($colors, white);
      position: absolute;
      left: 51%;
      bottom: 0;
      transform: translateX(-50%);
    }
  }

  &__resultItemContent {
    display: flex;
    justify-content: flex-start;
    border-radius: 15px;
    width: 8.68vw;
    align-items: flex-end;


    span {
      font-size: 13vh;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.94px;
      color: map-get($colors, white);
      margin-bottom: -5.2vh;
    }
  }

  &__answers {
    display: flex;
    flex-wrap: wrap;
    margin: 7vh -50px 0;
    column-gap: 50px;
  }

  &__answersItem {
    cursor: pointer;
    flex: 0 0 calc(50% - 25px);
    max-width: 50%;

    &:nth-child(even) {
      #{ $self }__answerContent {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:nth-child(odd) {
      #{ $self }__answerContent {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &__answer {
    position: relative;

    span {
      font-size: 12vh;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.76;
      letter-spacing: 3.33px;
      text-align: center;
      color: map-get($colors, white);
      position: relative;
      z-index: 5;
      padding-left: 20px;
    }
  }

  &__answerContent {
    position: absolute;
    left: 0;
    top: -2px;
    bottom: 17px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    gap: 10px;
    padding: 0 2.26vw 0 9.08vw;
    border-radius: 10px;
    font-size: 4vh;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.83px;
    color: map-get($colors, white);

    &.length-25 {
      font-size: 3vh;
    }

    &.two-answers-length-25 {
      font-size: 3vh;
    }

    &_result {
      justify-content: space-between;
      flex-wrap: nowrap;
      hyphens: auto;

      // svg{
      //   position: absolute;
      //   top:0;
      //   right:0;
      // }
      .result-icon{
        padding: 0;
        justify-content: center;
        display: flex;
      }
    }
  }

  &__fullResult {
    width: 100%;
    margin: 0 -55px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__fullResult-TopList {
    display: flex;
    align-items: flex-end;
    margin: 0 -25px;
    padding: 0 55px;
  }

  &__fullResult-ListWrapper {
    padding: 0 55px 48px;
    text-align: center;
  }

  &__fullResult-List {
    padding: 2.62vh;
    border-radius: 27px;
    background-color: map-get($colors, green);
    text-align: left;
    min-width: 270px;
    max-height: 38vh;
    overflow-y: auto;
  }

  &__fullResult-ListItem {
    font-size: 3.3vh;

    &:not(:last-of-type) {
      margin-bottom: 0px;
    }

    span {
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.69px;
      text-align: center;
      color: map-get($colors, white);

    }
  }

  &__fullResult-TopListItem {
    padding: 0 25px;
    text-align: center;
    position: relative;

    &:first-of-type {
      order: 1;
    }

    &:nth-of-type(2) {
      order: 0;
    }

    &:last-of-type {
      order: 2;
    }

    &:after {
      content: attr(data-name);
      font-size: 4vh;
    }
  }

  &__fullResult-TopListItemBar {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 20px;

    span {
      font-size: 4.2vh;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.83px;
      text-align: center;
      color: map-get($colors, white);
    }

  }

  &__fullResult-BottomList {
    display: flex;
    flex: 1;
    align-items: flex-end;
    margin-bottom: 6vh;
  }

  &__fullResult-BottomListItem {
    display: flex !important;
    justify-content: center;
    align-items: center;

    &__icon {
      width: 41px;
      height: 75px;
      cursor: pointer;

      &-right {
        transform: scaleX(-1);
      }
    }

    &__nested {
      height: 16.7vh;

      background: map-get($colors, green);
      cursor: pointer;
      color: map-get($colors, white);
      .title{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 10px 20px;
        font-size: 3.4vh;
        font-weight: bold;
        color: map-get($colors, white);
      }
    }
  }

  .ranking-container {
    align-self: end;
    margin-left: 2.78vw;

    .ranking-card {
      padding: 2.7vh 2.37vw 2.7vh 2.02vw;
      border-radius: 27px;
      border: solid 3px #a0c814;
      background-color: #a0c814;

      .ranking-title {
        font-family: $GoetheFFClan;
        font-size: 4vh;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.83px;
        color: #fff;
        text-align: center;
      }

      .ranking-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;

        .ranking-text {
          font-family: $GoetheFFClan;
          font-size: 3.4vh;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.69px;
          color: #fff;
        }

        .ranking-name {
          margin-left: 30px;
        }
      }
    }
  }

  .results-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .point-cup-container {
    margin-top: 13.09vh;
    width: 39.27vh;
    height: 39.27vh;
    align-self: center;
    margin-bottom: 20px;
    flex-direction: column;

    .confetti-emitter {
      align-self: center;
    }
  }
}
