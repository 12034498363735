@import 'src/styles/base/variables';

.c-card {
  &-image {
    position: relative;
    cursor: pointer;
    display: flex;
    height: calc(36vw * 0.72);
    @media screen and (max-width: map_get($breakppoints, lg)) {
      height: calc(50vw * 0.72);
    }
    img{
      height: calc(36vw * 0.72 - 100px);
      @media screen and (max-width: map_get($breakppoints, lg)) {
        height: calc(50vw * 0.72 - 100px);
      }
  
      object-fit: contain;
    }
    svg{
      object-fit: cover;
      display: block;
      width: 100%;
      height: auto;
    }
    &-tags {
      position: absolute;
      right: 0;
      bottom: 0;

      span {
        margin: 10px;
      }
    }

    .image-container {
      height: fit-content;

      &.portrait {
        width: auto;
        margin: 0 auto;

        img {
          width: auto;
          height: calc(36vw * 0.72 - 100px);
        }
      }

      &.landscape {
        margin: auto 0;

        img, svg {
          height: auto;
          padding-bottom: 100px;
        }

        .copyright {
          bottom: 110px;
        }
      }

      img {
        height: auto;
      }
    }
  }

  &-body {
    padding: 15px 10px;
    position: relative;
  }

  &-category {
    font-size: 12px;
  }

  &-title {
    font-size: 21px;
    font-weight: bold;
    margin-top: 5px;
  }

  &-description {
    font-size: 18px;
    font-weight: bold;
    margin-top: 4px;
  }

  &-author {
    font-size: 18px;
    margin-top: 14px;
    margin-bottom: 8px;
  }

  &-star {
    position: absolute;
    top: 15px;
    right: 10px;
    font-size: 21px;
    cursor: pointer;
  }

  &-date {
    position: absolute;
    top: 15px;
    right: 40px;
    font-size: 16px;
  }

  &-menu-dots {
    position: absolute;
    top: 11px;
    right: 0;
    cursor: pointer;
  }

  &-menu-item {
    font-size: 18px;
    font-family: $HelveticaNeueLt;
    color: map-get($colors, black);
  }

  &-dropdown-container {
    border: solid 1px map-get($colors, black) !important;
    border-radius: 8px !important;
    background-color: map-get($colors, white);
  }

  &-more {
    position: absolute;
    right: 0;
    font-size: 18px;
    bottom: 23px;
    cursor: pointer;
  }

  &-buttons {
    margin-top: 30px;

    button {
      border-radius: 5px !important;
      border: solid 4px map-get($colors, green);
      background-color: map-get($colors, green) !important;
      color: map-get($colors, white) !important;
      padding: 9px 20px;
      font-size: 14px;
      font-weight: bold;
      width: auto !important;
      margin: 0 20px 20px 0;
    }
  }

  &-tags {
    margin-top: 32px;

    span {
      font-size: 18px;
      font-weight: bold;
      margin-right: 20px;
      color: map-get($colors, black);
    }
  }

  &-line {
    border-bottom: solid 2px #000000;
  }
}
